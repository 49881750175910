import WebServiceRequest from '../Api/WebServiceRequest'

class StaticPageCreateRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('StaticPage/Create')
    }
    setParams(data){
        super.setRequestParamDataObj(data)
    }
}

class StaticPageUpdateRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('StaticPage/Update')
    }
    setParams(data){
        super.setRequestParamDataObj(data)
    }
}

class StaticPageGetByTypeRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
    }
    setType(type){
        super.setTag('StaticPage/GetByType?type='+type);
    }
}

export {
    StaticPageCreateRequest,
    StaticPageUpdateRequest,
    StaticPageGetByTypeRequest
};